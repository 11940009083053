.tab-label {
  font-family: 'Poppins' !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  color: #7d8fb3 !important;
}

.inner-tab-label {
  font-family: 'Poppins' !important;
  font-weight: 500 !important;
  font-size: 11px !important;
  color: #7d8fb3 !important;
  border: 1px solid #445fd2 !important;
  border-radius: 10px !important;
  min-height: 0 !important;
  margin-right: 20px !important;
  padding: 9px 5px 9px 5px !important;
}

.company-profile-container {
  color: rgba(107, 122, 153, 1);
}

.page-heading-section {
  display: flex;
  /* justify-content: space-between; */
  gap: '1px';
}

.page-heading p {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 10px;
  color: rgba(107, 122, 153, 1);
  display: inline-block;
  margin: 0 40px 0 0;
}

.page-heading h5 {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 14px;
  color: rgba(107, 122, 153, 1);
  margin: 0;
}

.MuiDivider-root {
  margin: 10px 0 !important;
  padding: 0;
}

.single-row {
  display: flex;
  justify-content: space-between;
}

.single-row .MuiBox-root {
  flex: 0 0 50%;
  display: flex;
  align-items: center;
  justify-content: start;
}

.single-row .MuiBox-root p {
  width: 140px;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 12px;
}

.single-row input[type='text'] {
  width: 337px;
  height: 37px;
  border: 1px solid rgba(240, 241, 245, 1);
  border-radius: 10px;
  background: #fff;
  color: #000;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 12px;
  padding: 10px;
  margin: 10px;
}

.single-row input.disabled {
  background: rgba(240, 241, 245, 1);
}

.product-title-colum {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin: 15px 0;
}

.product-title-colum .product-title-box {
  display: flex;
  flex-direction: column;
  color: var(--text-color-text-1, #1b212d);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.product-title-colum .product-title-box span {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 10px;
  color: rgba(120, 119, 139, 1);
}

.product-title-colum .product-title-box label {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 12px;
  width: 170px;
  color: var(--text-color-text-1, #1b212d);
  line-break: auto;

}

.black-text {
  color: rgba(27, 33, 45, 1);
}

.page-filters-tabs .MuiTabs-indicator {
  display: none !important;
}

.page-filters-tabs .inner-tab-label {
  font-family: 'Poppins' !important;
  font-weight: 500 !important;
  font-size: 11px !important;
  color: rgba(181, 188, 204, 1) !important;
  border: 1px solid rgba(230, 233, 238, 1) !important;
  border-radius: 10px !important;
  min-height: 0 !important;
  margin-right: 20px !important;
  padding: 9px 5px 9px 5px !important;
}

.total-products-badge {
  padding: 20px;
  background: rgba(250, 251, 252, 1);
  width: 180px;
  border-radius: 20px;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 12px;
  color: rgba(107, 122, 153, 1);
  text-align: center;
}

.products-filters-row {
  display: flex;
  gap: 20px;
  align-items: end;
  margin: 0 0 30px 0;
}
.date {
  color: #1b212d;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.products-filters-row .category-filter-horizontal {
  display: flex;
  flex-direction: row;
  width: 200px;
  gap: 10px;
  align-items: center;
}

.products-filters-row .category-filter-horizontal p {
  color: rgba(107, 122, 153, 1);
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 9px;
}

.products-filters-row .category-filter-horizontal select {
  background: rgba(255, 255, 255, 1);
  border-radius: 20px !important;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 9px;
  padding: 10px;
  border: 1px solid #fff;
  color: rgba(107, 122, 153, 1);
  width: 150px;
}

.products-filters-row .category-filter {
  display: flex;
  flex-direction: column;
  width: 200px;
  gap: 1px;
}

.products-filters-row .category-filter p {
  color: rgba(107, 122, 153, 1);
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 9px;
}

.products-filters-row .category-filter select {
  background: rgba(255, 255, 255, 1);
  border-radius: 20px !important;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 9px;
  padding: 10px;
  border: 1px solid #fff;
  color: rgba(107, 122, 153, 1);
}

.company-details-tabs {
  margin-left: 20px;
}

.common-table td.font-9 {
  color: rgba(120, 119, 139, 1);
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 9px;
}

.common-table td.qty {
  font-family: 'Kumbh Sans';
  font-weight: 500;
  font-size: 14px;
  color: rgba(120, 119, 139, 1);
}

.common-table td.price-with-icon p {
  margin: 0;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 12px;
}

.common-table td.font-12-500 {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 12px;
}

.common-table td.font-12-400 {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 12px;
  color: #1b212d;
}

.common-table td.font-12-600 {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 12px;
}

.common-table td.font-14-500 {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
}

.common-table td.font-10-400 {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 10px;
  color: #78778b;
}

.common-table td.font-10-600 {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 10px;
  color: #78778b;
}

.common-table td.font-10-500 {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 10px;
  color: #78778b;
}
.blueText {
  color: #445fd2;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.common-table td.td-link {
  text-align: center;
  color: #445fd2;
}
.common-table td.td-link label {
  width: 100%;
  display: inline-block;
  margin-top: 15px;
}
.common-table td.td-link span {
  height: 1px;
  background: #445fd2;
  width: 56px;
  display: inline-block;
}

.common-table td .status-box {
  display: flex;
  flex-direction: column;
  gap: 2px;
  background: rgba(68, 95, 210, 0.13);
  margin: 10px 0;
  border-radius: 4px;
  padding: 5px;
  min-height: 30px;
}

.common-table td .status-box p {
  margin: 0;
  font-family: 'Kumbh Sans';
  font-weight: 500;
  font-size: 13px;
  color: rgba(68, 95, 210, 1);
}

.common-table td .status-box span {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 8px;
  color: rgba(120, 119, 139, 1);
}

.common-table td.font-14-600-Kumbh {
  font-family: 'Kumbh Sans';
  font-weight: 600;
  font-size: 14px;
}

.wallet-cart {
  width: calc(100% - 480px);
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 80px;
}

.wallet-cart .MuiBox-root {
  display: flex;
  flex-direction: column;
}

.wallet-cart .MuiBox-root label {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 10px;
  color: rgba(107, 122, 153, 1);
}

.wallet-cart .MuiBox-root span {
  color: rgba(27, 33, 45, 1);
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 12px;
}

.common-table td.date-time-label label {
  font-family: 'Kumbh Sans';
  font-weight: 500;
  font-size: 14px;
  color: rgba(27, 33, 45, 1);
}

.common-table td.date-time-label span {
  font-family: 'Kumbh Sans';
  font-weight: 400;
  font-size: 13px;
  color: rgba(146, 158, 174, 1);
}

.border-bottom {
  border-bottom: 1px solid #929eae;
}

.products-filters-row .category-filter {
  display: flex;
  flex-direction: row;
  width: 200px;
  gap: 10px;
  align-items: center;
}

.products-filters-row .category-filter p {
  color: rgba(107, 122, 153, 1);
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 9px;
}

.products-filters-row .category-filter select {
  background: rgba(255, 255, 255, 1);
  border-radius: 20px !important;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 9px;
  padding: 10px;
  border: 1px solid #fff;
  color: rgba(107, 122, 153, 1);
  width: 90px;
}
/* Add these styles to your Company.Modules.css file */
.marketplace-filters {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
}

.marketplace-filters select {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  min-width: 150px;
}

.page-header-dropwon-section {
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 20px;
  flex-wrap: wrap;
}